@import '~antd/dist/antd.less';

@media only screen and (max-width: 428px) {
  .terms-checkbox {
    margin-bottom: 10px;
  }

  .document-content {
    padding: 0;
  }

  .document-title {
    font-size: 18px !important;
    margin-bottom: 20px !important;
  }

  .document-content .site-layout-background {
    margin: 20px 15px 20px 15px;
  }
}

@media only screen and (min-width: 428px) {
  .document-content {
    padding: 64px;
    max-width: 1340px;
  }
}

// corresponds with AntD xs breakpoint
@media only screen and (max-width: 576px) {
  // shrink button and header padding to keep the icon from wrapping over
  .header {
    padding: 0 12px;
  }

  .header .ant-btn {
    padding: 0;
  }

  // change search page padding for mobile
  .search-header {
    padding: 24px;
  }

  .search-form-content {
    padding: 0;
  }

  .search-footer {
    padding: 24px;
  }

  .search-state-dropdown {
    width: 312px;
  }
}

// corresponds with AntD sm breakpoint
@media only screen and (min-width: 576px) {
  // update padding to search page elements for desktop/tablet
  .search-header {
    padding: 0;
  }

  .search-form-content {
    padding: 24px;
  }

  .search-footer {
    padding: 24px 0;
  }

  .search-state-dropdown {
    width: 100px;
  }
}

.search-results-cards-wrapper {
  margin-top: 24px;
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
}

.search-results-card {
  position: relative;
  padding: 24px 24px 48px;
  background: #fff;
  min-width: 345px;
  max-width: 750px;
}

.search-results-type {
  font-size: 14px;
  color: #8792a1;
  text-transform: uppercase;
  margin-bottom: 0;
}

.search-results-name {
  font-size: 24px;
  font-weight: 400;
  margin-top: 0;
}

.search-results-last-updated {
  position: absolute;
  bottom: 1em;
}

.search-notes {
  padding-top: 16px;
}

.search-notes-subtitle {
  font-size: 16px;
}

.search-notes-text {
  white-space: pre-line;
  line-height: 22px;
  overflow-wrap: anywhere;
  word-break: break-word;
}

.search-notes-text button {
  padding: 0 5px;
  height: 22px;
  border: none;
}

.search-tree-node-icon {
  vertical-align: top;
  padding-top: 5px;
}

.search-header {
  margin-bottom: 16px;
}

.search-form-content {
  max-width: 1340px;
}

.search-footer {
  background-color: #f0f2f5;
}

.site-layout-background {
  background: #fff;
}

@layout-header-background: #ffffff;@layout-header-padding: 0 25px;@layout-footer-background: #ffffff;@layout-footer-padding: 0 0 10px 0;@table-body-sort-bg: #ffffff;@primary-color: #0e3d69;@font-family: 'Open Sans', Helvetica, Arial, sans-serif;@avatar-bg: #8792a1;@btn-disable-color: #ffffff;@btn-disable-bg: rgba(#0e3d69, 0.5);