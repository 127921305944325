.login-title {
  color: @primary-color;
  font-size: 50px;
  font-weight: 700;
  line-height: 63px;
}

.login-body {
  color: #495b65;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.login-image {
  height: auto;
  width: 100%;
}

.login-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
}

.flex-item-left,
.flex-item-right {
  flex: 50%;
}

@media (min-width: 1240px) {
  .flex-container {
    width: 1240px;
    margin: 0px auto;
  }
}

@media (min-width: 749px) {
  .flex-container {
    padding-top: 64px;
  }
}

@media (max-width: 768px) {
  .login-title {
    font-size: 30px;
    line-height: 38px;
  }
}

@media (max-width: 428px) {
  .flex-item-right,
  .flex-item-left {
    flex: 100%;
  }
}

@layout-header-background: #ffffff;@layout-header-padding: 0 25px;@layout-footer-background: #ffffff;@layout-footer-padding: 0 0 10px 0;@table-body-sort-bg: #ffffff;@primary-color: #0e3d69;@font-family: 'Open Sans', Helvetica, Arial, sans-serif;@avatar-bg: #8792a1;@btn-disable-color: #ffffff;@btn-disable-bg: rgba(#0e3d69, 0.5);